<template>
  <div>
  <header class="header header-container">
    <img class="header-img h-img-01" src="../assets/header-01.jpg"/>
    <p class="h-title"><strong>Tropical USA Painting</strong></p>
    <img class="header-img h-img-02" src="../assets/header-02.jpg"/>


  </header>
    <div class="quote">
      <h3>Contact us for a free quote!</h3>
    </div>
    </div>

</template>

<script>
  export default {
    name: 'Header'
  }
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.header-container {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  background-color: rgb( 255, 255, 255);
  text-align: center;
  /*opacity: 100%;*/
  height: 250px;
}

.h-title {
  color: #2A4390;
  font-size: 60px;

}

.quote {
  background-color: rgb(119, 141, 169, .9); /* https://www.colorhexa.com/778da9*/
  color: #0D1B2A;
  text-align: center;
  grid-column: 1 / 6;
  font-size: 25px;
  height: 50px;
  padding-top: 10px;
}
.quote h3 {
  margin:5px;
}

.header-img{
  height: 250px;
  vertical-align: middle;
  text-align: center;
  width: 300px;
 }

</style>
