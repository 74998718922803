<template>
  <article class="home home-container">
    <img class="owner-img" src="../assets/owner.jpg"/>
    <div class="text">
      <h2>Introduction</h2>
      <p>My name is Julio Cardoso, and my crew and I have a passion for perfection when it comes to painting. I've been
        painting all over
        Massachusetts for over two decades and am the owner and operator of Tropical USA Painting company.<br><br>
        Tropical USA Painting is a local Eastern Massachusetts company dedicated to delivering amazing experiences to
        its customers. Established in 2005, we have been committed to delivering our best to private home owners,
        businesses and more. We primarily work in the Middlesex county of Eastern Massachusetts, however depending on
        the job we are willing to discuss potential jobs outside of our normal operating area.
        <br><br>
        We strive to use the best equipment we can for each and every job. Our brands of choice are Sherwin Williams and
        Benjamin Moore. We take our work very seriously and won’t report a job finished until it is something we can
        take full pride in.
      </p>
    </div>
  </article>
</template>


<script>

export default {
  name: 'Home'

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
article {
  height: 425px;
}

.home {
  background-color: rgb(65, 90, 119, .88);
  margin-top:15px;
}

.home-container {
  display: grid;
  grid-template-columns: 1fr 4fr;
}

.owner-img {
  height: 400px;
  margin: 10px;
}

.text {
  color: white;
  font-size: 20px;
  text-align: justify;
  margin: 10px;
}

.text h2 {
  margin: 10px;
  text-align: center;
}

</style>
