<template>
  <div class="container">
    <Header/>
    <Home/>
    <ContactUs/>
  </div>

</template>

<script>
import Home from './components/Home.vue'
import Header from './components/Header.vue'
import ContactUs from './components/ContactUs'

export default {
  name: 'App',
  components: {
    Home,
    Header,
    ContactUs
  }
}
</script>

<style>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  background: url('assets/bg.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  /*background-size: cover;*/
  background-size: auto;
  height: 100%;

}

html, body {
    margin: 0;
    height: 100%;
    width: 100%;
}
html {
    overflow: auto;
}

.container {
  margin: auto;
  width: 1200px;
  display: grid;
  /*grid-template-rows: 1fr 2fr 2fr;*/
  grid-template-rows: repeat(auto-fit, minmax(250px, 1fr));

}

header {
  grid-column: 1;
  grid-row: 1;
}

article {
  grid-column: 1;
  grid-row: 2;
}
footer {
  grid-column: 1;
  grid-row: 3;
}


</style>
