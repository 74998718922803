<template>
  <footer class="contact">
    <div class="quote">
      <div class="quote-details">
      <h2>Quote Info</h2>
      <p>
        Quotes can be requested for free but are subject to change over time as the economy and product prices
        shift.<br><br>
        Quotes will require a scheduled visitation and walk through to the desired project to get an accurate
        understanding of potential material and labor costs.
      </p>
      </div>
      <img class="work-img-01" src="../assets/quality-work.jpg"/>
    </div>

    <div class="contact-info">
      <p>
        Email: <a href="mailto:Tropcompany@hotmail.com?subject=Requesting a Quote">tropcompany@hotmail.com</a><br>
        Call/Text: <a href="tel:6177992686">617-799-2686</a>
        <br><br>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSc0Tkk6UUzYLEDSAo3WH8HzaoJcGuGb92t44-h4tRpCx3kg9A/viewform?usp=sf_link">Satisfaction Survey</a>
      </p>
    </div>
  </footer>

</template>

<script>

export default {
  name: 'ContactUs'
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.quote {
  display: grid;
  grid-template-columns: 4fr 1fr;
}
.quote-details p {
  text-align: center;
  font-size: 16px;
}
.work-img-01 {
  height: 200px;
}

p {
  font-size: 16px;
}

.contact {
  background-color: #0D1B2A;
  text-align: center;
  /*border-radius: 10px;*/
  /*width: 100%;*/
  color: #E0E1DD;
  padding: 5px;
  margin-top:15px
}

.contact-info {
  background-color: #1B263B;
  text-align: center;
  /*border-radius: 10px;*/
  /*width: 100%;*/
  color: #E0E1DD;
  padding: 5px;
  margin-top: 15px;
}

a {
  color: #00aaff;
}

</style>
